import "./App.css";

import Site from "./site";

function App() {
  return (
    <div className="App">
      <Site />
    </div>
  );
}

export default App;
